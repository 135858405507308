<template>
  <item-edit-view
    title="Вакансия"
    :subtitle="item ? item.name : null"
    :loading="loading"
  >
    <template v-slot:right>
      <v-btn
        right
        color="success"
        :loading="saving"
        @click.prevent="save()"
      >
        <v-icon small>mdi-content-save</v-icon>
        Сохранить
      </v-btn>
    </template>

    <v-card flat>
      <v-form v-if="!loading && item">
        <v-card-text>
          <div>
            <v-text-field
              v-model.trim="item.name"
              label="Название"
              @input="$v.item.name.$touch()"
              :error-messages="nameErrors"
            ></v-text-field>
          </div>
          <!-- <div class="d-flex justify-space-around align-center mb-6">
            <v-text-field
              v-model.trim="item.place"
              label="Место"
              @input="$v.item.place.$touch()"
              :error-messages="placeErrors"
            ></v-text-field>
            <div class="pa-4">или</div>
            <v-select
              v-if="clinics"
              v-model="item.clinicId"
              :items="clinics"
              item-text="name"
              item-value="id"
              clearable
              @input="$v.item.clinicId.$touch()"
              :error-messages="clinicIdErrors"
            ></v-select>
          </div> -->
          <div>
            <label class="label">Описание вакансии</label>
            <div class="field control">
              <quill
                v-model.trim="item.description"
                :options="quillToolbar"
              >
              </quill>
            </div>
          </div>
        </v-card-text>
      </v-form>
    </v-card>
  </item-edit-view>
</template>

<script>
import { helpers, required } from 'vuelidate/lib/validators'

import JobService from '../../services/jobs'
import clinicsService from '../../services/clinics'
import createOrUpdateViewMixin from '../../mixins/createOrUpdateView'
import { QUILL_BASIC } from '../../components/elements/quilljs/configs'

const placeOrClinicValidator = (equalTo) =>
  helpers.withParams({ type: 'placeOrClinic', eq: equalTo }, function (value, parentVm) {
    return helpers.req(value) || helpers.req(helpers.ref(equalTo, this, parentVm))
  })

export default {
  mixins: [createOrUpdateViewMixin(JobService, {
    clinics: null,
    description: null,
    quillToolbar: QUILL_BASIC
  })],
  beforeRouteEnter (to, from, next) {
    clinicsService.getAll({ limit: 30 })
      .then(({ items }) => {
        next(vm => {
          vm.clinics = items
        })
      }).catch(err => {
        next(false)
      })
  },
  computed: {
    nameErrors () {
      const errors = []
      if (!this.$v.item.name.$dirty) return errors
      !this.$v.item.name.required && errors.push('Необходимо указать')
      return errors
    },
    placeErrors () {
      const errors = []
      if (!this.$v.item.place.$dirty) return errors
      !this.$v.item.place.placeOrClinic && errors.push('Необходимо указать')
      return errors
    },
    clinicIdErrors () {
      const errors = []
      if (!this.$v.item.clinicId.$dirty) return errors
      !this.$v.item.clinicId.placeOrClinic && errors.push('Необходимо указать')
      return errors
    }
  },
  validations: {
    item: {
      name: {
        required
      },
      clinicId: {
        placeOrClinic: placeOrClinicValidator('place')
      },
      place: {
        placeOrClinic: placeOrClinicValidator('clinicId')
      }
    }
  }
}
</script>
